<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title">{{ $t('page.title.new_master_class') }}:</h3>
      <div class="float-right">
        <router-link :to="{'name':'master-classes-list'}">
          <b-button size="sm" variant="outline-primary">{{ $t('btn.back') }}</b-button>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <b-card>
            <b-card-header><b>{{ $t('page.general_information') }}</b></b-card-header>
            <b-card-body>
              <label>{{ $t('page.masterClass.name') }}</label>
              <p>
                <input v-model="form.name" class="form-control">
              </p>

              <label>{{ $t('page.description') }}</label>
              <p>
                <textarea v-model="form.description" class="form-control"></textarea>
              </p>

              <label>{{ $t('page.masterClass.author') }}</label>
              <p>
                <multiselect
                             multiple
                             v-model="selectedAuthors"
                             :options="authors"
                             track-by="key"
                             label="value"
                ></multiselect>
              </p>

              <label>{{ $t('page.status') }}</label>
              <p>
                <vue-select
                    v-model="form.status"
                    :options="statuses" :reduce="status => status.value"
                    label="text"
                ></vue-select>
              </p>

            </b-card-body>
          </b-card>
        </div>
        <div class="col-6">
          <b-card>
            <b-card-header><b>{{ $t('page.masterClass.trailer') }}</b></b-card-header>
            <b-card-body>
              <label>{{ $t('page.masterClass.trailer') }}</label>
              <p>
                <input v-model="form.trailer" class="form-control">
              </p>
              <vimeo v-if="form.trailer" :vimeo-id="form.trailer" title="form.name"></vimeo>

              <label>{{ $t('page.masterClass.introTrailer') }}</label>
              <p>
                <input v-model="form.introTrailer" class="form-control">
              </p>

              <vimeo v-if="form.introTrailer" :vimeo-id="form.introTrailer" title="form.name"></vimeo>

            </b-card-body>
          </b-card>
        </div>

      </div>
      <div class="buttons float-right">
        <b-button type="button" @click="saveData()" variant="success" class="mr-1">{{ $t('actions.save') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>

import alertify from 'alertifyjs';
import axios from "axios";
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css";
import {mapState} from "vuex";
import { VueSelect } from "vue-select";
import "vue-select/dist/vue-select.css";
import Vimeo from "@/components/vimeo/Vimeo.vue";

export default {
  props: ['apiKey'],
  components: {
    Multiselect,
    VueSelect,
    Vimeo
  },
  data() {
    return {
      form: {},
      selectedAuthors: [],
      statuses: [
        {value: 'active', text: 'Active'},
        {value: 'inactive', text: 'Inactive'},
        {value: 'bonus', text: 'Bonus'},
      ],
    };
  },
  mounted() {
    this.getAuthors();
    this.selectedAuthors = this.authors.filter((el) => this.masterClass.authors.includes(el.key));
  },
  computed: {
    ...mapState({
      authors: (state) => state.masterClassStore.authors,
    }),
  },
  methods: {
    getAuthors() {
      this.$store.dispatch('masterClassStore/getAuthors');
    },
    getAuthorById(id) {
      let obj = this.authors.find(author => author.key === id);

      return obj ? obj.value : null;
    },
    saveData() {
      const url = '/master-class';
      this.form.authors = this.selectedAuthors.map((el) => el.key);

      axios.post(url, this.form)
          .then(() => {
            alertify.success('Klasa została dodana');
            this.$router.push('master-classes');
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
  },
  watch: {
    $route() {
    }
  }
}
</script>

